<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <page-title text="Corrieri" />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-text-field
            light
            v-model="search"
            prepend-icon="search"
            @click:prepend="getCorrieri"
            append-icon="cancel"
            @click:append="resetItems"
            placeholder="Cerca" />
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!corrieri.length">
          Nessun corriere trovato
        </v-alert>
        <v-list class="mt-2" two-line v-if="corrieri.length">
          <template v-for="(corriere, index) in corrieri">
            <v-list-tile @click="openDialog(corriere)" :key="'tile-'+corriere._id">
              <v-list-tile-content>
                <v-list-tile-title class="text-capitalize" v-text="corriere.nome" />
                <v-list-tile-sub-title v-text="corriere.targa" />
              </v-list-tile-content>
              <v-list-tile-action>
                <v-icon v-if="corriere.ordine" @click.stop="deleteCorriere(corriere)" color="error">delete</v-icon>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="corriere._id" v-if="index < corrieri.length - 1" />
          </template>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
      <v-dialog
        v-model="showDialog"
        transition="dialog-bottom-transition"
        :overlay="false"
        width="500">
        <v-card>
          <v-toolbar class="grey lighten-3" card>
            <v-toolbar-title>
              <span v-if="corriere._id">{{corriere.nome}}</span>
              <span v-if="!corriere._id">Nuovo corriere</span>
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon @click.stop="showDialog=false"><v-icon>close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="pa-0">
            <v-card>
              <v-form ref="formCorriere" v-model="validFormCorriere">
                <v-container fluid grid-list-xl>
                  <v-layout row wrap>
                    <v-flex>
                      <v-text-field
                        label="Nome*"
                        v-model="corriere.nome"
                        :rules="[$rules.required, $rules.maxlength(100)]" />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex>
                      <v-text-field
                        label="Targa"
                        v-model="corriere.targa"
                        :rules="[$rules.maxlength(10)]" />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex>
                      <v-textarea
                        auto-grow
                        label="Note"
                        v-model="corriere.note"
                        :rules="[$rules.maxlength(4000)]" />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex>
                      <v-btn
                        class="ml-0"
                        color="primary"
                        :disabled="!validFormCorriere"
                        @click.native="save()">
                        Salva
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-tooltip left>
        <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click.stop="openDialog()">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Nuovo corriere</span>
      </v-tooltip>
    </v-layout>
    <modal-confirm ref="confirm" />
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import PageTitle from '@/components/common/PageTitle'
import modalConfirm from '@/components/common/ModalConfirm'
import _clone from 'lodash/clone'

export default {
  data () {
    return {
      corrieri: [],
      corriere: {},
      search: null,
      pages: 0,
      page: 1,
      paginate: 1000,
      validFormCorriere: false,
      showDialog: false
    }
  },
  components: {
    PageTitle,
    'modal-confirm': modalConfirm
  },
  methods: {
    getCorrieri () {
      this.$plsqlReadAll('corriere', {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      })
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.corrieri = response.data.items
            this.pages = response.data.pages
          }
        })
        .catch(err => {
          console.log('errore client', err)
          this.$showMessage({ type: 'error', text: 'Errore' })
        })
    },
    deleteCorriere (corriere) {
      this.corriere = corriere
      // this.confirm = true
      this.$refs.confirm.show({
        modalTitle: 'Sicuro?',
        modalText: 'Stai per cancellare il corriere <strong>' + corriere.nome + '</strong>.<br/>Confermi?',
        modalButtonOk: 'Elimina',
        modalFunctionOk: this.remove,
        modalObj: corriere
      })
    },
    remove () {
      this.$plsqlDelete('corriere', this.corriere._id)
        .then(response => {
          $EventBus.$emit('message', {
            type: 'success',
            text: 'Corriere eliminato'
          })
          this.$refs.confirm.hide()
          this.getCorrieri()
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-02292') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare il corriere perché usato' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
          this.$refs.confirm.hide()
        })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getCorrieri()
    },
    save () {
      if (this.$refs.formCorriere.validate()) {
        this.$plsqlSave('corriere', this.corriere)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Corriere salvato'
            })
            this.showDialog = false
            this.getCorrieri()
          })
          .catch(err => {
            console.log(err)
            $EventBus.$emit({ type: 'error', text: 'Errore salvataggio corriere' })
          })
      }
    },
    openDialog (corriere) {
      this.corriere = corriere ? _clone(corriere) : { }
      this.showDialog = true
      if (!corriere) this.$refs.formCorriere.reset()
    }
  },
  mounted () {
    this.getCorrieri()
  }
}
</script>
